@use "../variables" as *;

.svc-creator-popup {
  &.sv-popup--modal {
    background-color: var(--ctr-popup-haze-background-color, $background-semitransparent);
  }

  &.sv-popup--modal > .sv-popup__container {
    background-color: var(--ctr-popup-background-color, $background-dim-light);
    border-radius: var(--ctr-popup-corner-radius, var(--sjs-corner-radius, 4px));
    box-shadow:
      var(--ctr-popup-shadow-1-offset-x, 0px) var(--ctr-popup-shadow-1-offset-y, 2px)
        var(--ctr-popup-shadow-1-blur, 8px) var(--ctr-popup-shadow-1-spread, 0px)
        var(--ctr-popup-shadow-1-color, rgba(0, 76, 68, 0.1)),
      var(--ctr-popup-shadow-2-offset-x, 0px) var(--ctr-popup-shadow-2-offset-y, 16px)
        var(--ctr-popup-shadow-2-blur, 32px) var(--ctr-popup-shadow-2-spread, 0px)
        var(--ctr-popup-shadow-2-color, rgba(0, 76, 68, 0.1)),
      var(--ctr-popup-shadow-3-offset-x, 0px) var(--ctr-popup-shadow-3-offset-y, 32px)
        var(--ctr-popup-shadow-3-blur, 64px) var(--ctr-popup-shadow-3-spread, 0px)
        var(--ctr-popup-shadow-3-color, rgba(0, 76, 68, 0.1));

    .sv-footer-action-bar {
      gap: var(--ctr-popup-buttons-gap, calcSize(1.5));
    }

    & > .sv-popup__body-content {
      background-color: var(--ctr-popup-background-color, $background-dim-light);
      border-radius: var(--ctr-popup-corner-radius, var(--sjs-corner-radius, 4px));

      padding: var(--ctr-popup-padding-top, calcSize(4)) var(--ctr-popup-padding-right, calcSize(4))
        var(--ctr-popup-padding-bottom, calcSize(4)) var(--ctr-popup-padding-left, calcSize(4));

      gap: var(--ctr-popup-gap, 32px);
      box-shadow: unset;
    }
  }

  &.sv-popup--dropdown-overlay .sv-popup__scrolling-content {
    height: auto;
  }

  &.sv-popup--overlay.sv-popup--tablet {
    background-color: var(--ctr-popup-haze-background-color, $background-semitransparent);
  }
  &.sv-popup--overlay {
    // background-color: var(--ctr-popup-menu-background-color, $background);
    background-color: var(--ctr-popup-haze-background-color, $background-semitransparent);
  }
  &.sv-popup--overlay:not(.sv-popup--dropdown-overlay) {
    .sv-popup__body-content {
      background-color: var(--ctr-popup-background-color, $background-dim);
    }

    .sv-popup__button.sv-popup__button {
      background-color: var(--ctr-button-background-color-cta, $primary);
      border: 2px solid var(--ctr-button-background-color-cta, $primary);
      color: var(--ctr-button-text-color-cta, $primary-foreground);
    }
  }

  .sv-popup__pointer {
    &:after {
      content: " ";
      border-bottom: calcSize(1) solid var(--ctr-popup-menu-pointer-color, $background);
    }
  }

  .sv-popup__container {
    background-color: transparent;
    border-radius: var(--ctr-popup-menu-corner-radius, calcSize(0.5));
    box-shadow:
      var(--ctr-popup-menu-shadow-1-offset-x, 0px) var(--ctr-popup-menu-shadow-1-offset-y, 2px)
        var(--ctr-popup-menu-shadow-1-blur, 6px) var(--ctr-popup-menu-shadow-1-spread, 0px)
        var(--ctr-popup-menu-shadow-1-color, rgba(0, 0, 0, 0.1)),
      var(--ctr-popup-menu-shadow-2-offset-x, 0px) var(--ctr-popup-menu-shadow-2-offset-y, 8px)
        var(--ctr-popup-menu-shadow-2-blur, 16px) var(--ctr-popup-menu-shadow-2-spread, 0px)
        var(--ctr-popup-menu-shadow-2-color, rgba(0, 0, 0, 0.1));
  }

  .sv-popup__body-header {
    @include ctrMediumBoldFont;
    color: var(--ctr-popup-title-color, $foreground);
  }

  &.sv-popup--dropdown-overlay.sv-popup--tablet .sv-popup__body-content,
  .sv-popup__body-content {
    border-radius: var(--ctr-popup-menu-corner-radius, calcSize(0.5));
  }

  &.svc-property-editor.sv-popup--overlay:not(.sv-popup--dropdown-overlay) .sv-popup__body-content {
    background-color: var(--ctr-popup-background-color, $background-dim);
    border-radius: calc(4 * (var(--ctr-popup-menu-corner-radius, 4px)))
      calc(4 * (var(--ctr-popup-menu-corner-radius, 4px))) 0px 0px;
  }

  &.sv-popup--dropdown,
  &.sv-popup--dropdown-overlay {
    .sv-popup__body-content {
      background-color: var(--ctr-popup-menu-background-color, $background);
      padding: 0;
    }
  }

  &.sv-popup--dropdown.svc-toolbox-subtypes {
    .sv-popup__body-content {
      background: unset;
    }
  }

  //styles for dropdown overlay
  &.sv-popup--dropdown-overlay {
    .sv-popup__button.sv-popup__button {
      color: var(--ctr-actionbar-button-text-color, $primary);
      border-radius: var(--ctr-actionbar-button-corner-radius, calcSize(12.5));
      padding: var(--ctr-actionbar-button-padding-top-medium-text, calcSize(0.5))
        var(--ctr-actionbar-button-padding-right-medium-text, calcSize(2))
        var(--ctr-actionbar-button-padding-bottom-medium-text, calcSize(0.5))
        var(--ctr-actionbar-button-padding-left-medium-text, calcSize(2));
    }

    .sv-popup__body-footer {
      background-color: var(--ctr-popup-menu-footer-background-color, $background-dim);
      border-top: 1px solid var(--ctr-popup-menu-footer-border-color, $border-light);
      padding: var(--ctr-popup-menu-footer-padding-top, calcSize(1)) var(--ctr-popup-menu-footer-padding-right, 0)
        var(--ctr-popup-menu-footer-padding-bottom, calcSize(1)) var(--ctr-popup-menu-footer-padding-left, 0);
    }

    .sv-popup__button:disabled {
      color: var(--ctr-actionbar-button-text-color-disabled, $foreground);
      opacity: var(--ctr-actionbar-button-opacity-disabled, 0.25);
    }
  }

  &.sv-dropdown-popup.sv-popup--dropdown-overlay {
    .sv-popup__body-footer {
      background-color: var(--ctr-contextual-menu-footer-background-color, $background-dim);
    }
  }

  .sv-popup__content {
    @include ctrDefaultFont;
    color: var(--ctr-popup-message-color, #404040);
  }
}
